import request from '@/utils/request'



export function queryChainSummary() {
    return request(
        'get',
        '/v1',
       `/chn/chain/ethchain/summary`
    );
}

// 新闻资讯
// https://app.read.cnpkp.com/v1/cms/news/p/1-10
export function queryNewsList(pageNumber, pageSize, params) {
    return request(
        'post',
        '/v1',
       `/cms/news/p/${pageNumber}-${pageSize}`,
       params
    );
}
// 新闻详情
// https://app.read.cnpkp.com/v1/cms/news/900-8371789-201440
export function queryNewsDetail(newsCode) {
    return request(
        'get',
        '/v1',
       `/cms/news/${newsCode}`
    );
}